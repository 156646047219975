<template>
  <div>
    <button v-if="type == 'button'" :type="type" :class="'btn ' + classx">
      <i v-if="icon" :class="'fa fa-fw ' + icon" style="color:white"> </i>
      <span v-if="name" :class="namecolor||'text-white'">{{ name }}</span>
    </button>
    <router-link v-else-if="type == 'routerlink'" :to="todetails" :class="'btn ' + classx"
      style=";color:white">
      <i v-if="icon" :class="'fa fa-fw ' + icon" style="color:white"> </i>
      <span v-if="name" :class="namecolor||'text-white'">{{ name }}</span>

    </router-link>
  </div>
</template>



<!-- <buttoncomp classx="btn-save" name="Save" type="button" v-on:click="savedata" icon="fa-save"></buttoncomp> -->
<script>
export default {
  props: [
    'classx',
    'name',
    'icon',
    'type',
    'todetails',
    'namecolor'
  ],
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
